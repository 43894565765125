import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Cloud Storage App Template | Uizard"
    category="Tablet Templates"
    url="/templates/tablet-templates/cloud-storage-tablet-app/"
    metaDescription="Our cloud storage tablet app design template comes fully equipped with all the features you need to design your own cloud app. Try it now with Uizard."
    description="
    h2:A cloud storage app design template for tablet
    <br/>
    Introducing our cloud storage tablet app design template. This <a:https://uizard.io/templates/>UI template</a> boasts a stunning and simplistic design, and includes all the basic UX best practices and user flows required for any successful tablet app design.
    <br/>
    h3:Uizard puts you on design cloud 9
    <br/>
    Boasting a stunning, clean aesthetic that is sure to tick all your boxes, our cloud storage tablet app template is here to impress. With Uizard, customization has never been so simple. Update screens, change text, adapt user journeys, and you can even change the entire theme of the template to match your own branding.
    <br/>
    h3:Design your own cloud storage tablet app in minutes
    <br/>
    Make the <a:https://uizard.io/prototyping/>UX/UI prototyping</a> process quicker than ever before with Uizard. Create a functional prototype of your cloud tablet app in no time with our premade template. You can also work with your team in real-time with our smart project-sharing features!
    "
    pages={[
      "A minimalist app homepage demonstrating clear intent through gorgeous typography and imagery",
      "A cloud storage interface screen boasting the core elements any successful cloud storage app requires",
    ]}
    projectCode="EwyOVz7l73uWnVwaQVVa"
    img1={data.image1.childImageSharp}
    img1alt="cloud storage web app design template cover screen"
    img2={data.image2.childImageSharp}
    img2alt="cloud storage web app design template upload screen"
    img3={data.image3.childImageSharp}
    img3alt="cloud storage web app design template favourite images screen"
    img4={data.image4.childImageSharp}
    img4alt="cloud storage web app design template overview screen"
    img5={data.image5.childImageSharp}
    img5alt="cloud storage web app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/cloud-sharing-tablet-app/cloud-storage-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/cloud-sharing-tablet-app/cloud-storage-tab-app-upload.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/cloud-sharing-tablet-app/cloud-storage-tab-app-fav.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/cloud-sharing-tablet-app/cloud-storage-tab-app-overview.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/cloud-sharing-tablet-app/cloud-storage-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
